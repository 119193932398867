// CSS
import "./styles/global.scss";

// JS
import $ from 'jquery';


window.jQuery = window.$ = $;
require('jquery-validation');
require("slick-carousel");
require('datatables.net');
require('prismjs');

import Bloodhound from 'corejs-typeahead/dist/bloodhound.js';
window.Bloodhound = Bloodhound;
require('corejs-typeahead/dist/typeahead.jquery.js');
require('./externals/funnelback.autocompletion-2.6.0.js');

// const videoPlaceholder = document.querySelector('.video-placeholder');
// videoPlaceholder.addEventListener('click', function () {

//     const videoUrl = this.dataset.video;

//     // Create a new iframe element
//     const iframe = document.createElement('iframe');
//     iframe.src = videoUrl + '&autoplay=1';
//     iframe.frameborder = 0;
//     iframe.allowfullscreen = true;
//     iframe.class = "video-iframe"; // Optional class for styling

//     // Replace the placeholder image with the iframe
//     this.parentNode.replaceChild(iframe, this);

//     // Load the YouTube iframe player API (immediately after iframe creation)
//     const script = document.createElement('script');
//     script.src = "https://www.youtube.com/iframe_api";
//     document.body.appendChild(script);
// });

